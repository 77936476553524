@import url('https://fonts.googleapis.com/css?family=Rubik:400,700');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
    font-family: 'Berkshire Swash';
    src: url('/fonts/BerkshireSwash-Regular.eot');
    src: url('/fonts/BerkshireSwash-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BerkshireSwash-Regular.woff') format('woff'),
        url('/fonts/BerkshireSwash-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
