@mixin svgInc($image, $padding, $width, $mode) {
    width: $width;
    padding-bottom: $padding;
    background: url('/images/home/#{$image}.svg') no-repeat center center / #{$mode};
    position: absolute!important;
}

.stage {
    $s:&;

    position: fixed;
    z-index: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    background: url('/images/home/stage.svg') no-repeat 70% center / cover;

    @include mq(md){
        background-position: center center;
    }

    &--two {
        background: none;
    }

    &__cityleft {
        display: none;
        @include mq(md){
            @include trans;
            @include svgInc('cityleft', 37%, 70%, cover);
            display: block;
            bottom: -7%;
            background-position: right bottom;
            right: 42%;
            left: auto!important;
            top: auto!important;
        }
    }

    &__cityright {
        @include trans;
        @include svgInc('cityright', 147%, 110%, cover);
        top: auto!important;
        bottom: -7%;

        @include mq(md){
            @include svgInc('cityright', 47%, 54%, cover);
            bottom: -7%;
            background-position: left bottom;
            left: 54%!important;
        }
    }

    &__tree {
        @include trans(all, 2s, ease-out);
        width: 90%;
        padding-bottom: 220%;
        position: absolute!important;
        top: 15% !important;
        background-position: center top;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;

        img {
            position: absolute;
            width: 100%;
            height: auto;
            top: 0;

        }

        @include mq(md) {

            width: 37%;
            padding-bottom: 150%;
            background-position: center top;
        }
    }

    &__cloud1 {
        @include svgInc('cloud1', 8%, 24%, contain);
        left: 5% !important;
        top: 15% !important;
        animation: clouds 90s infinite linear;

        @include mq(md){
            @include svgInc('cloud1', 2.5%, 7%, contain);

            top: 22% !important;
        }
    }

    &__cloud2 {
        @include svgInc('cloud2', 9%, 28%, contain);
        left: 30% !important;
        top: 20% !important;
        animation: clouds 90s infinite linear;

        @include mq(md){
            @include svgInc('cloud2', 2.5%, 8%, contain);
            left: 30% !important;
            top: 25% !important;
        }
    }

    &__cloud3 {
        @include svgInc('cloud3', 9%, 28%, contain);
        left: 60% !important;
        top: 12% !important;
        animation: clouds 90s infinite linear;

        @include mq(md){
            @include svgInc('cloud3', 3.5%, 10%, contain);
            left: 65% !important;
            top: 32% !important;
        }
    }

    &__cloud4 {
        display: none;

        @include mq(md){
            @include svgInc('cloud4', 4.5%, 12%, contain);
            left: 86% !important;
            top: 18% !important;
            display: block;
            animation: clouds 90s infinite linear;
        }
    }

    &__cloud5 {
        display: none;

        @include mq(md){
            @include svgInc('cloud1', 2.5%, 7%, contain);
            left: 90% !important;
            top: 27% !important;
            display: block;
            animation: clouds 90s infinite linear;
        }
    }

    &__bird1 {
        @include svgInc('bird1', 30px, 45px, contain);
        animation: birdone 8s infinite linear, birdOneBounce 1s infinite linear;
        backface-visibility: visible!important;
        top: auto!important;
        left: 35% !important;
        bottom: 60%;

        @include mq(sm) {
            @include svgInc('bird1', 40px, 65px, contain);
        }
    }

    &__globe {
        width: 20%;
        padding-bottom: 26%;
        background: url('/images/home/globe.png') no-repeat center center / contain;
        position: absolute!important;
        top: 20%!important;
        left: 15%!important;

        @include mq(md){
            width: 10%;
            padding-bottom: 14%;
            left: 25%!important;
        }
    }

    &__spaceship {
        @include trans;
        width: 24%;
        padding-bottom: 18%;
        //background: url('/images/home/spaceship.png') no-repeat center center / contain;
        position: absolute!important;
        top: auto!important;
        left: auto!important;
        right: 5%;
        bottom: 32%;
        z-index: 5;
        animation: spaceship 3s forwards ease, spaceshipBounce 2s infinite linear;
        -webkit-backface-visibility: visible;
        -webkit-perspective: 1000;

        img {
            position: absolute;
            width: 100%;
            height: auto;
            top: 0;

        }

        @include mq(md){
            width: 12%;
            padding-bottom: 7%;
            right: 30%;
        }
    }

    &__astronaut {
        @include trans;
        width: 34%;
        padding-bottom: 60%;
        background: url('/images/home/astronaut.png') no-repeat center center / contain;
        position: absolute!important;
        top: auto!important;
        left: auto!important;
        right: -35%;
        bottom: 32%;
        z-index: 5;

        @include mq(md){
            width: 12%;
            padding-bottom: 20%;
            right: -30%;
        }
    }

    &__ship {
        display: none;
        @include mq(md){
            @include svgInc('ship', 26%, 20%, contain);
            display:block;
            left: auto !important;
            top: auto !important;
            bottom: 20%;
            right: 37%;
            animation: ship 3s infinite linear;
        }
    }

    &__submarine {
        display: none;
        @include mq(md){
            @include trans($d: 1s);
            @include svgInc('submarine', 12%, 25%, contain);
            display:block;
            left: -30% !important;
            top: auto !important;
            bottom: 20%;
            z-index: 2;
        }
    }

    &__rocket {
        display: none;
        @include mq(md){
            @include trans($d: 1s);
            @include svgInc('rocket', 10%, 25%, contain);
            display:block;
            left: auto !important;
            top: auto !important;
            bottom: 25%;
            right: -30%;
            z-index: 2;
        }
    }

    &.-tween {
        #{$s}__tree {
            transform: translate3d(0,-10%,0) scale3d(3.6, 3.6, 1) !important;
        }
        #{$s}__bird1,
        #{$s}__cityleft,
        #{$s}__cityright {
            opacity: 0;
        }
        #{$s}__spaceship {
            right: -25%;
        }
        #{$s}__rocket {
            right: -5%;
        }
        #{$s}__submarine {
            left: 0% !important;
        }
    }

    &.-ending {
        #{$s}__bird1,
        #{$s}__cityleft,
        #{$s}__cityright {
            opacity: 0;
        }

        #{$s}__tree {
            transform: translate3d(0,-252%,0) scale3d(3.6, 3.6, 1) !important;
            background-position: center bottom;

            @include mq(md){
                transform: translate3d(0,-102%,0) scale3d(3.6, 3.6, 1) !important;
            }
        }

        #{$s}__spaceship {
            right: 10%;
            transition-delay: 1.5s;
            transition-duration: 1s;
            width: 30%;
            padding-bottom: 25%;

            @include mq(md){
                width: 16%;
                padding-bottom: 10%;
            }
        }

        #{$s}__rocket {
            right: 85%;
            transition-duration: 1.5s;
            transform: rotate(160deg)!important;
            bottom: 45%;
        }

        #{$s}__astronaut {
            right: 55%;
            animation: spaceship 3s forwards ease, spaceshipBounce 2s infinite linear;
        }
    }
}

// animations
@include keyframes(birdone) {
    0% {
        transform: translateX(0) rotate3d(0,1,0,0deg);
        z-index: 1;
    }
    19% {
        transform: translateX(-18vw) rotate3d(0,1,0,0deg);
        z-index: 1;
    }
    20% {
        transform: translateX(-20vw) rotate3d(0,1,0,180deg);
        z-index: 1;
    }
    64% {
        transform: translateX(38vw) rotate3d(0,1,0,180deg);
        z-index: 1;
    }
    65% {
        transform: translateX(40vw) rotate3d(0,1,0,0deg);
        z-index: 5;
    }
    100% {
        transform: translateX(0) rotate3d(0,1,0,0deg);
        z-index: 5;
    }
}

@include keyframes(birdOneBounce) {
    0% {
        bottom: 60%;
    }
    25% {
        bottom: 62%;
    }
    50% {
        bottom: 60%
    }
    75% {
        bottom: 58%;
    }
    100% {
        bottom: 60%;
    }
}

@include keyframes(ship) {
    0% {
        bottom: 20%;
    }
    25% {
        bottom: 20.5%;
    }
    50% {
        bottom: 20%
    }
    75% {
        bottom: 19.5%;
    }
    100% {
        bottom: 20%;
    }
}

@include keyframes(clouds) {
    0% {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
    50% {
        transform: translate3d(100vw,0,0);
        opacity: 1;
    }
    51% {
        transform: translate3d(100vw,0,0);
        opacity: 0;
    }
    52% {
        transform: translate3d(-50vw,0,0);
        opacity: 0;
    }
    53% {
        transform: translate3d(-50vw,0,0);
        opacity: 1;
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}

@include keyframes(spaceship){

    0% {
        transform-origin: 100% 20%;
        transform: perspective(400px) translate3d(200%,0,-100px) scale3d(.1,.1,.1) rotateY(-60deg);
    }

    100% {
        transform-origin: 100% 80%;
        transform: perspective(0);
    }
}

@include keyframes(spaceshipBounce) {
    0% {
        bottom: 32%;
    }
    25% {
        bottom: 34%;
    }
    50% {
        bottom: 32%
    }
    75% {
        bottom: 30%;
    }
    100% {
        bottom: 32%;
    }
}

@include keyframes(yetiRight) {
    0% {
        transform:translateX(120%);
    }
    100% {
        transform:translateX(0);
    }
}

@include keyframes(yetiUp) {
    0% {
        transform:translateY(50%);
    }
    100% {
        transform:translateY(0);
    }
}
