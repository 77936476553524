.wish-card {
    $w:&;

    //@include trans;

    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    cursor: pointer;

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        color: $white;
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        justify-content: space-between;

        &__person {
            display: flex;
            align-items: center;
        }

        &__img {
            position: relative;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;

            img {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &__name,
        &__number {
            font-family: $altFont;
            font-size: 16px;
            font-weight: normal;
            margin: 0;
        }

        &__number{
            text-align: right;
        }
    }

    &__body {
        flex-grow: 2;
        padding: 10px 0;

        &__text {
            font-size: 24px;
            font-weight: 400;
        }
    }

    &__date {
        font-family: $altFont;
        font-size: 14px;
    }

    &__controls {
        display: none;
    }

    &__overlay {
        display: none;
        @include mq(md){
            
            @include trans($d: 1s);

            display: block;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            background: transparentize(#00463c, .1);
            z-index: 1000;
            position: fixed;
            top:0;
            right: 0;
            left: 0;
            bottom:0;

            &.-active {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.-open {
        @include mq(md){

            position: fixed;
            top: calc(50% - 225px);
            left: calc(50% - 325px);
            width: 650px;
            height: 450px;
            z-index: 5000;
            padding-bottom: 0;
            cursor: default;

            #{$w}__wrapper {
                padding: 40px;
                animation: wishCardFade 1s forwards $easing;
            }

            &:before {
                position: absolute;
                content: '';
                width: 60px;;
                height: 70px;
                background: url('/images/home/bird2.svg') no-repeat bottom center / contain;
                right: 20px;
                bottom: 100%;
            }

            #{$w}__controls {
                display: block;

                &__close {
                    background: $mainColor;
                    border-radius: 50%;
                    color: $white;
                    width: 50px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    position: fixed;
                    top: -100px;
                    right: -100px;
                }

                &__next,
                &__prev {
                    border-radius: 50%;
                    background: $white;
                    color: $mainColor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;
                    position: absolute;
                    bottom:40px;
                    width: 32px;
                    height: 32px;
                    border: none;

                    &.-disabled {
                        opacity: .5;
                        pointer-events: none;
                        cursor: default;
                    }
                }

                &__next{
                    right: 40px;
                }
                &__prev {
                    right: 80px;
                }
            }

            #{$w}__header {

                &__person {
                    align-items: flex-start;
                }

                &__img {
                    width: 60px;
                    height: 60px;
                    margin-right: 20px;
                }

                &__name,
                &__number {
                    font-size: 21px;
                }
            }

            #{$w}__body {
                padding: 20px 0;

                &__text {
                    font-size: 36px;
                }
            }

            #{$w}__date {
                position: absolute;
                top: 70px;
                left: 120px;
            }
        }
    }
}

.-reset{
    transform: none!important;
}

@include keyframes(wishCardFade) {
    0% {
        opacity: 0;
        transform: translateY(100vh);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
