.comercial {
    &__main {
        height: 100%;
        width: 100vw;
        position: relative;
        z-index: 10;
        padding-top: 90px;

        @include mq(md){
            padding-top: 200px;
            padding-bottom: 0;
        }
    }

    .container,
    .row,
    .col-xs-24 {
        height: 100%;
    }

    &__title {
        color: $mainColor;
        text-align: center;
        font-family: $altFont;
    }

    &__text {
        text-align: center;
        font-size: 18px;
        max-width: 85%;
        margin: 15px auto;

        @include mq(md){
            max-width: 60%;
        }
    }

    &__screen {
        background: url('/images/home/screen.svg') no-repeat top center / cover;
        position: absolute;
        bottom: 0;
        top: 30%;
        left: 10px;
        right: 10px;

        @include mq(md){
            top: 25%;
        }
    }

    &__video {
        width: 94.2%;
        position: absolute;
        left: 4.1%;
        top: 8%;
        padding-bottom: 52.7%;
        height: 0;

        @include mq(md){
            top: 12%;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            border: none;
        }
    }

    .stage {
        &__city {
            @include trans;
            @include svgInc('city', 90%, 150%, cover);
            bottom: -20%;
            background-position: center bottom;
            right: 0;
            left: auto!important;

            @include mq(md){
                @include svgInc('cityleft', 105%, 150%, cover);
                background-position: left bottom;
            }
        }
    }
}
