.header {

    $h: &;

    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 32px;
        top: 30px;

        @include mq(md){
            display: none;
        }

        span {
            background-color: $link;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            position: relative;
            z-index: 1;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }

        ._back {
            @include trans;

            background: $white;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            position: absolute;
            z-index: 0;
            top: 40%;
            right: 50%;
            transform: translate3d(50%,-50%,0);
            overflow: hidden;
            pointer-events: none;
        }
    }

    &__logo {
        width: 70px;
        height: 70px;
        position: absolute;
        top: 10px;
        left: 15px;
        z-index: 2;

        @include mq(md){
            width: 140px;
            height: 140px;
            top: 30px;
            left: 30px;
        }

        img {
            width: 100%;
        }
    }

    &__nav {
        @include trans;
        position: fixed;
        top: 85px;
        opacity: 0;
        border-top: 1px solid $mainColor;
        transform: translate3d(110%, -110%, 0);
        width: 100%;

        @include mq(md){
            position: absolute;
            right: 30px;
            opacity: 1;
            transform: none;
            width: auto;
            display: flex;
            border: none;
        }
    }

    &__menu {
        width: 100%;

        @include mq(md){
            display: flex;
        }

        li {
            border-bottom: 1px solid $lightGray;
            @include mq(md){
                margin-right: 30px;
                border: none;
            }
        }

        a {
            display: block;
            height: 40px;
            line-height: 40px;
            color: $mainColor;
            padding: 0 15px;
            font-family: $altFont;

            &:hover {
                text-decoration: none;

                @include mq(md){
                    color: $white;
                    border-bottom: 3px solid $white;
                }
            }

            &:focus {
                text-decoration: none;
            }

            @include mq(md){
                color: $white;
                font-size: 21px;
                padding: 0 5px;
            }
        }
    }

    &__social {
        display: flex;
        justify-content: center;
        padding-top: 40px;

        @include mq(md){
            padding-top: 0;
        }

        li {
            margin: 0 8px;
        }

        a {
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 50%;
            display: block;
            background: $link;
            color: $white;

            &:hover {
                color: $white;
                background: $linkHover;

                @include mq(md){
                    background: $white;
                    color: $linkHover;
                }
            }

            @include mq(md){
                background: $white;
                color: $link;
            }
        }
    }

    &.-open {
        #{$h}__nav{
            transform: translate3d(0,0,0);
            opacity: 1;
        }

        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }

            ._back {
                width: 100vw;
                height: 100vh;
                border-radius: 0;
                transform: translate3d(32px,-30px,0);
                top: 0;
                right: 0;
            }
        }
    }
}


//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
