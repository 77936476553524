.how {
    &__main {
        height: 100%;
        width: 100vw;
        position: relative;
        padding-top: 90px;

        @include mq(md){
            padding-top: 150px;
        }
    }

    &__data {
        @include mq(md){
            position: relative;
            padding-top: 20%;
        }
    }

    &__tagline {
        display: none;

        @include mq(sm){
            display: block;
            position: relative;
            padding-top: 70%;
        }
    }

    &__title {
        font-family: $altFont;
        font-size: 36px;
        color: $mainColor;
        text-align: center;
        margin: 0;

        @include mq(md){
            font-size: 54px;
        }
    }

    &__subtitle {
        font-weight: 700;
        font-size: 18px;
        margin: 20px 0;
        text-align: center;

        @include mq(md){
            max-width: 75%;
            margin: 30px auto;
        }
    }

    &__text {
        margin: 0;
        font-size: 18px;
        line-height: 28px;
        text-align: center;

        @include mq(md){
            max-width: 70%;
            margin: auto;
        }
    }

    &__yeti {
        position: absolute;
        animation: yetiUp 1s ease-out forwards;
        top: 80%!important;
        left: 0!important;
        right: 0;
        margin: auto;
        width: 66%;
        bottom: 0;

        @include mq(md) {
            width: 36%;
            right: 0;
            left: auto!important;
            top: 28%!important;
            animation: yetiRight 1s ease-out forwards;

            img {
                width: 100%;
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    .stage {
        $s:&;

        position: fixed;
        z-index: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        background: url('/images/home/mountains.svg') no-repeat 70% center / cover;

        @include mq(md){
            background-position: center center;
        }
    }
}
