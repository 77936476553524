.footer {
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding: 20px;

    a {
        color: $white;
        display: block;
        float: right;
        font-size: 14px;
    }
}
