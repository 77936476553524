.write {
    $w: &;

    &__main {
        min-height: 100%;
        width: 100vw;
        position: relative;
        z-index: 10;
        padding-top: 90px;
        padding-bottom: 250px;

        @include mq(md){
            padding-top: 150px;
            padding-bottom: 0;
        }
    }

    &__box {
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        margin: auto;
        overflow: hidden;
        height: auto;
        max-width: 600px;

        @include mq(md){
            position: relative;
            top:50px;
        }

        &.-success {
            #{$w}__form {
                transform: translateX(-100%);
            }
            #{$w}__success {
                transform: translateX(0);
            }
        }
    }

    &__form,
    &__success  {
        @include trans($d: 1s);

        position: absolute;
        width: 100%;
        height: 100%;
        padding: 15px;

        @include mq(md){
            padding: 30px;
        }
    }

    &__form{
        $f:&;

        position: relative;
        background: $white;

        &__title {
            color: $mainColor;
            font-family: $altFont;
            text-align: center;
        }

        &__text {
            text-align: center;

            @include mq(md){
                margin: 15px auto;
                max-width: 80%;
            }
        }

        &__group {
            margin-bottom: 10px;
            width: 100%;

            &--half {
                @include mq(sm){
                    width: 48%;
                    float:left;
                }
            }

            &--email {
                span {
                    font-size: 14px;
                }
                @include mq(sm){

                    display: flex;

                    #{$f}__input {
                        width: 48%;
                        margin-right: 10px;
                    }
                }
            }
        }

        &__input {
            background: $lightGray;
            height: 40px;
            line-height: 40px;
            padding: 0 10px;
            display: block;
            width: 100%;
            border:none;

            &--textarea {
                height: 120px;

                @include mq(sm){
                    height: 160px;
                }
            }

            &:focus {
                outline: none;
                box-shadow: none;
                border-bottom: 2px solid $mainColor;
            }
        }

        &__button {
            position: relative;
            width: 100%;
            height: 60px;
            border: none;
            padding: 0;
            background: none;

            span {
                @include trans;

                top: 0;
                display: block;
                width: 100%;
                height: 50px;
                background: $link;
                color: $white;
                font-size: 21px;
                line-height: 50px;
                text-align: center;
                border-radius: 10px;
                font-family: $altFont;
                position: absolute;
                z-index: 2;
            }

            &:before {
                @include trans;

                content: '';
                width: 100%;
                height: 50px;
                background: $linkHover;
                position: absolute;
                top: 10px;
                z-index: 0;
                border-radius: 10px;
                left: 0;
            }

            &:hover {
                span {
                    background: $linkHover;
                    color: $white;
                    top: 10px;
                }

                &:before {
                    top: 10px;
                }
            }
        }
    }

    &__success{
        background: $mainColor;
        transform: translateX(100%);
        color: $white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: 0;
        height: 60vh;

        &__title {
            color: $white;
            font-family: $altFont;
            text-align: center;
        }

        &__text {
            text-align: center;
            color: $white;
            font-size: 18px;

            @include mq(md){
                margin: 15px auto;
                max-width: 70%;
            }
        }

        &__share {
            display: flex;
            justify-content: center;
            align-items: center;

            ul {
                flex-basis: 160px;
                margin-right: 15px;

                li{
                    margin-bottom: 15px;
                }

                a {
                    color: $white;
                    font-size: 21px;
                    font-family: $altFont;
                    line-height:32px;
                    height: 32px;
                    display: block;

                    i {
                        background: $white;
                        color:$mainColor;
                        width: 32px;
                        height: 32px;
                        line-height: 32px;
                        border-radius: 50%;
                        text-align: center;
                        margin-right: 10px;
                    }
                }
            }

            ._rocket {
                width:85px;
                height: 213px;

                img {
                    height: 85px;
                    width: 213px;
                    transform: rotate(90deg) translate3d(30%,75%,0);
                }
            }
        }
    }


    .stage {
        &__cityleft {
            @include trans;
            @include svgInc('cityleft', 90%, 150%, cover);
            bottom: -20%;
            background-position: center bottom;
            right: 0;
            left: auto!important;

            @include mq(md){
                @include svgInc('cityleft', 115%, 180%, cover);
                background-position: left bottom;
            }
        }

        &__ship {
            @include svgInc('ship', 70%, 70%, contain);

            display: block;
            top: auto!important;
            right: auto!important;
            bottom: 20%;
            right: 47%;
            animation: ship 3s infinite linear;
            transform: translateY(30%)!important;

            @include mq(md){
                @include svgInc('ship', 50%, 50%, contain);
                bottom: 20%;
                right: 47%;
                transform: none!important;
            }
        }

        &__overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom:13%;
            background: rgba(50,200,220,.5);

            @include mq(md){
                bottom:28%;
            }
        }
    }
}
