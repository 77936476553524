.home {
    position: relative;

    &__intro {
        height: 100%;
        width: 100vw;
        position: relative;
        z-index: 5;
        top: 0;

        &__tagline {
            width: 70%;
            padding-bottom: 65%;
            position: absolute;
            left: 0;
            right: 0;
            top: 30%;
            margin: auto;

            @include mq(md){
                width: 28%;
                padding-bottom: 24%;
            }

            img {
                position: absolute;
                width: 100%;
            }
        }

        &__trigger {
            position: absolute;
            left: 0;
            right: 0;
            width: 50px;
            height: 50px;
            background: $white;
            margin: auto;
            bottom: 5%;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            i {
                font-size: 36px;
            }
        }

        &__cta {
            width: 250px;
            height: 70px;
            background: $link;
            color: $white;
            font-size: 24px;
            line-height: 70px;
            text-align: center;
            border-radius: 10px;
            position: absolute;
            bottom: calc(5% + 80px);
            font-family: $altFont;
            left: 0;
            right: 0;
            margin: auto;

            &:before {
                @include trans;

                content: '';
                width: 250px;
                height: 70px;
                background: $linkHover;
                position: absolute;
                top: 10px;
                z-index: -1;
                border-radius: 10px;
                left: 0;
            }

            &:hover {
                background: $linkHover;
                color: $white;
                bottom: calc(5% + 70px);

                @include mq(md){
                    bottom: calc(40% - 10px);
                }

                &:before {
                    top: 0;
                }
            }

            @include mq(md){
                bottom: 40%;
                right: 10%;
                left: auto;
            }
        }
    }

    &__wishes {
        $w: &;

        height: 100%;
        width: 100vw;
        position: relative;
        z-index: 5;
        padding-top: 90px;

        @include mq(md){
            padding-top: 150px;
        }

        &__tagline {
            @include trans;
            max-width: 300px;
            margin: auto;

            @include mq(sm){
                position: sticky;
                top: 40%;
            }
        }

        &__list {
            @include trans;

            padding-bottom: 450px;
        }

        &__item {
            margin-bottom: 30px;

            &:nth-child(3n+2){
                @include mq(md){
                    transform: translateY(30%);
                }
            }

            &:nth-child(3n+3){
                @include mq(md){
                    transform: translateY(60%);
                }
            }
        }

        &.-inactive {
            #{$w}__list,
            #{$w}__tagline {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    &__ending {
        height: 100%;
        width: 100vw;
        position: relative;
        z-index: 5;
        padding-top: 150px;
    }
}
